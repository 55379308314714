.tableTextPolitics{
    color: white;
    font-weight: 500;
    text-align: left;
    font-size: 3vh;
    font-family: Roboto;

}

.WOWPTitle {
    color: #FFF;
    font-family: Roboto;
    font-size: 6vh;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-decoration-line: underline;
}
.EOWPTitleColor {
    color: #FFA31A;
    font-family: Roboto;
    font-size: 4vh;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-decoration-line: underline;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;

}

.aboutUsStyle{
    height:20vh;
    width:25vh;
    margin-bottom:3vh
}

@media screen and (max-width: 768px) {

    .aboutUsStyle{
        height:30vw;
        width:40vw;
        margin-bottom:3vw
    }

    .tableTextPolitics{
        color: white;
        font-weight: 500;
        text-align: left;
        font-size: 4vw;
        font-family: Roboto;
    
    }
    .WOWPTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 7vw;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        text-decoration-line: underline;
    }
    .EOWPTitleColor {
        color: #FFA31A;
        font-family: Roboto;
        font-size: 6vw;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        text-decoration-line: underline;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: left;
    
    }
}