.tableTextReader{
    font-size: 3.2vh;
    color: white;
    font-weight: 500;
    text-align: left;
  font-family: Roboto;
  width: 60vh;

  margin-left: 4vh;


}

.tableTextReaderColor{
    color: #FFA31A;
  
}
.tableTextReaderDesc{
    font-size: 2.3vh;
    color: white;
    font-weight: 300;
    text-align: left;
  font-family: Roboto;
    width: 60vh;
}

.ReadersImage{
    height: 23.32vh;
    width: 23.32vh;
  }

  .imageLayout {
    display: flex;
    justify-content: space-between;
    flex-direction: 'row';
    text-align: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    .imageLayout {
      display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
    }

    .ReadersImage{
      height: 23.32vw;
      width: 23.32vw;
    }
    .tableTextReader{
      font-size: 3.2vw;
      color: white;
      font-weight: 500;
      text-align: left;
    font-family: Roboto;
    width: 60vw;
  
    margin-left: 4vh;
  
  
  }
  .tableTextReaderDesc{
    font-size: 2.3vw;
    color: white;
    font-weight: 300;
    text-align: left;
  font-family: Roboto;
    width: 60vw;
}
  }