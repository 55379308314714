.sectionCss{
    flex-direction: column;
    align-items:left;
    display: flex;
    justify-content: first baseline;
}


  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Inter;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }

  .aboutUsBg{
    height: 40vh;
    width: 40vw;
  }

  .ImageAlign{
    display:flex;
    justify-content:space-around;
    flex-direction:row;
    text-align:center;
    align-items:center
  }
  .ImageCaptionAbout{
    font-family: Inter;
      font-size:2.5vw;
      color:#fff;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
  }


   /* Media query for mobile responsiveness */
   @media screen and (max-width: 768px) {
    .aboutUsBg{
      height: 25vh;
      width: 100%;
    }
   
    .aboutUs-section{
      
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: left;
      background-color: black;
      min-height: 90vh;
      font-size: 11.80;
      padding: 0 5vw 0 5vw;
      }
      .sectionTitleAbout{
        color: #FFF;
      font-family: Roboto;
      font-size:9vw;
      font-style: normal;
      font-weight: bold;
      line-height: normal;  
      text-decoration-line: underline;
      }
      .ImageCaptionAbout{
        font-family: Roboto;
        font-size: 5vw;
          color:#fff;
          font-style: normal;
          line-height: normal;
      }
    

    
      .contentDescAboutUS{
    
        color: #fff;
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        line-height: normal;
        font-weight: 500;
        font-size: 5vw;
        }
    .ImageAlign{
      display:contents;
      justify-content:space-around;
      flex-direction:row;
      text-align:left;
      align-items:flex-start
    }
    .ImageCaption{
      font-family: Inter;
      font-size:4vw;
      color:#fff;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
   }



/* Small Desktops/Laptops - 1024x768 */
   @media (min-width: 768px) and (max-width: 1279px) and (max-height : 723px) and (min-height : 0px){
    .sectionTitleAbout{
      color: #FFF;
    font-family: Roboto;
    font-size: 9vh;
    font-style: normal;
    font-weight: bold;
    line-height: normal;  
    text-decoration-line: underline;
    }
    .contentDescAboutUS{
    
    color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: 500;

    }
    .aboutUs-section{
      
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    font-size: 11.80;
    padding: 0 5vh 0 5vh;
    }
    .ImageAlignAboutUS{
      display:flex;
      justify-content:center;
      flex-direction:row;
      text-align:center;
      align-items:center;
      
    }
    .ImageCaptionAbout{
      font-family: Roboto;
      font-size: 5vh;
        color:#fff;
        font-style: normal;
        line-height: normal;
        font-weight: bold;
    }
    .aboutUsBg{
      height: 45vh;
      width: 80vh;
      margin-right: 5vh;
    }
  }
  @media (min-width: 768px) and (max-width: 1279px) and (min-height : 723px)  {
 
    .sectionTitleAbout{
      color: #FFF;
    font-family: Roboto;
    font-size: 9vh;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-decoration-line: underline;
    }
    .contentDescAboutUS{
      color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: 500;

    }
    .aboutUs-section{
      
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    font-size: 11.80;
    padding: 0 5vh 0 5vh;
    }
    .ImageAlignAboutUS{
      display:flex;
      justify-content:center;
      flex-direction:row;
      text-align:center;
      align-items:center;

      
    }
    .ImageCaptionAbout{
      font-family: Roboto;
      font-size: 5vh;
        color:#fff;
        font-style: normal;
        line-height: normal;
    }
    .aboutUsBg{
      height: 45vh;
      width: 45vw;
      margin-right: 5vh;
    }
  }


  
/* Small Desktops/Laptops - 1280x800 */
@media (min-width: 1279px) and (max-width: 1365px) and (max-height : 723px) and (min-height : 0px){
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}
@media (min-width: 1279px) and (max-width: 1365px) and (min-height : 723px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 40vh;
    width: 45vw;
    margin-right: 5vh;
  }
}




/* Small Desktops/Laptops - 1366x768 */
@media (min-width: 1365px) and (max-width: 1439px)  and (max-height : 723px) and (min-height : 0px) {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}
@media (min-width: 1365px) and (max-width: 1439px)  and (min-height : 723px) {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}


/* Medium Desktops - 1440x900 */
@media (min-width: 1439px) and (max-width: 1599px) and (max-height : 723px) and (min-height : 0px){
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}
@media (min-width: 1439px) and (max-width: 1599px)  and (min-height : 723px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}



/* Standard Desktops - 1600x900 */
@media (min-width: 1599px) and (max-width: 1919px) and (max-height : 723px) and (min-height : 0px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}
@media (min-width: 1599px) and (max-width: 1919px) and (min-height : 723px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}

/* Standard Desktops (Full HD) - 1920x1080 */
@media (min-width: 1919px) and (max-width: 2559px) and (max-height : 723px) and (min-height : 0px){
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}
@media (min-width: 1919px) and (max-width: 2559px)  and (min-height : 723px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}

/* Large Desktops (2K QHD) - 2560x1440 */
@media (min-width: 2559px) and (max-width: 3439px) and (max-height : 723px) and (min-height : 0px) {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}
@media (min-width: 2559px) and (max-width: 3439px)  and (min-height : 723px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}


/* Ultra-Wide Monitors - 3440x1440 */
@media (min-width: 3439px) and (max-width: 3839px)  and (max-height : 723px) and (min-height : 0px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}
@media (min-width: 3439px) and (max-width: 3839px)  and (min-height : 723px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}

/* Large Desktops (4K UHD) - 3840x2160 */
@media (min-width: 3839px)  and (max-height : 723px) and (min-height : 0px){
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}
@media (min-width: 3839px)  and (min-height : 723px)  {
  .sectionTitleAbout{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescAboutUS{
    color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  }
  .aboutUs-section{
    
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: black;
  min-height: 90vh;
  font-size: 11.80;
  padding: 0 5vh 0 5vh;
  }
  .ImageAlignAboutUS{
    display:flex;
    justify-content:center;
    flex-direction:row;
    text-align:center;
    align-items:center;

    
  }
  .ImageCaptionAbout{
    font-family: Roboto;
    font-size: 5vh;
      color:#fff;
      font-style: normal;
      line-height: normal;
  }
  .aboutUsBg{
    height: 45vh;
    width: 45vw;
    margin-right: 5vh;
  }
}