.dashboard-container {
    text-align: center;
    background-color: black;
    min-height: 100vh;
    color: #fff;

  }
  
  .dashboard-heading {
    color: #fff;
    font-size: 5vw;
  }
  .dashboard-content {
    color: #fff;
    font-size: 3vw;
  }
  .coupon-code {
    font-size: 5vw;

    margin-top: 20px; /* Adjust as needed */
  }
  
  .data-list {
    font-size: 3vw;
    min-width: 100vw;

    margin-top: 30px; /* Adjust as needed */
  }
  
  .list-item {
    border-color: #fff;
    border-width: 1;
    list-style: none;
    padding: 10px;
    background-color: #000;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  