/* .App {
  text-align: center;
  font-family: Roboto
}

.App-logo {
  height: 40vmin;
  poRoboto-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* App.css */
/* 
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

header {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}

main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content {
  width: 70%;
  box-sizing: border-box;
  padding: 10px;
}

.sidebar {
  width: 25%;
  box-sizing: border-box;
  padding: 10px;
  background-color: #f4f4f4;
}

footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}

/* Media query for responsiveness */
/* @media screen and (max-width: 768px) {
  main {
    flex-direction: column;
  }

  .content,
  .sidebar {
    width: 100%;
  }
} */ 


/* App.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}



main {
}

.centered-section-contact{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  max-height: 120.25vh;
  padding-top: 10.25vh;

  min-width: 90vw;
}

.centered-section-main{

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  min-height: 80vh;
  font-size: 11.80;

}
a{
  color: #FF9F1A;
}
.centered-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  max-height: 100vh;
  min-height: 100vh;
  padding-top: 10.25vh;
}

.startPadding{
  padding-top: 10.25vh;

}

.centered-section-choose{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  /* min-height: 100vh; */
  padding-top: 10.25vh;
}
.mainIntro{
  color: #FFF;

text-align: center;
font-family: Roboto;
font-size: 6.08rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.mainIntroquote{
  color: #FFF;

text-align: center;
font-family: Roboto;
font-size: 1.66rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.sucessDialogDesc{
  color: #FFF;

text-align: center;
font-family: Roboto;
font-size: 1.4rem;
font-style: normal;
font-weight: lighter;
line-height: normal;
}
/* Navbar.css */

.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 3vw;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;


}
.sectionTitleColor{
  color: #FFA31A;
font-family: Roboto;
font-size: 3vw;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-align: left;
display: flex;
flex-direction: column;
align-items: left;
}

.contentDesc{
  color: #fff;
text-align: left;
padding:10px 9.6vw 0px 4.6vw;
font-family: Roboto;
font-size: 2vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.strikeColor{
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contentDescContact{
  color: #fff;
text-align: left;
font-family: Roboto;
font-size: 2vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.contentDescList{
  color: #FF9F1A;
text-align: left;
font-family: Roboto;

font-size: 2vw;
font-style: normal;
font-weight: 600;

}

.buttonStyle{
  border-radius: 1vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
color: #FFF;
font-family: Roboto;
font-size: 1.5vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}



.listDesc{
  color:#FFF
}

.footer{
  color: #FFA31A;
font-family: Roboto;
font-size: 3.5vh;
font-style: normal;
line-height: normal;
}

.desktopBr{
  margin-top: 40px;
}
.desktopBrInside{
  margin-top: 40px;
}


a.disabled {
  poRoboto-events: none;
  cursor: default;
    
}


/* Small Desktops/Laptops - 1024x768 */

  @media (min-width: 768px) and (max-width: 1279px) and (max-height : 723px) and (min-height : 0px){
    .centered-section-main{

      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: black;
      min-height: 88.31vh;
      font-size: 11.80;
    
    }
    .mainIntro{
      color: #FFF;
    
    text-align: center;
    font-family: Roboto;
    font-size: 14vh;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    }
    .mainIntroquote{
      color: #FFF;
    
    text-align: center;
    font-family: Roboto;
    font-size: 4vh;
    font-style: normal;
    font-weight: lighter;
    line-height: normal;
    }
    .sucessDialogDesc{
      color: #FFF;
    
    text-align: center;
    font-family: Roboto;
    font-size: 3vh;
    font-style: normal;
    font-weight: lighter;
    line-height: normal;
    }
  }
  @media (min-width: 768px) and (max-width: 1279px) and (min-height : 723px)  {
    /* Styles for 1024x768 screens */
    .centered-section-main{

      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: black;
      min-height: 88.31vh;
      font-size: 11.80;
    
    }
    .mainIntro{
      color: #FFF;
    
    text-align: center;
    font-family: Roboto;
    font-size: 12vw;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    }
    .mainIntroquote{
      color: #FFF;
    
    text-align: center;
    font-family: Roboto;
    font-size: 4vh;
    font-style: normal;
    font-weight: lighter;
    line-height: normal;
    }
    .sucessDialogDesc{
      color: #FFF;
    
    text-align: center;
    font-family: Roboto;
    font-size: 3vh;
    font-style: normal;
    font-weight: lighter;
    line-height: normal;
    }

  }



/* Small Desktops/Laptops - 1280x800 */
@media (min-width: 1279px) and (max-width: 1365px) and (max-height : 723px) and (min-height : 0px){
  /* Styles for 1280x800 screens */
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 14vh;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
}
@media (min-width: 1279px) and (max-width: 1365px) and (min-height : 723px)  {
  /* Styles for 1280x800 screens */
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 14vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 3vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
}


/* Small Desktops/Laptops - 1366x768 */
@media (min-width: 1365px) and (max-width: 1439px)  and (max-height : 723px) and (min-height : 0px) {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }

}
@media (min-width: 1365px) and (max-width: 1439px)  and (min-height : 723px) {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 13vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }

}

/* Medium Desktops - 1440x900 */
@media (min-width: 1439px) and (max-width: 1599px) and (max-height : 723px) and (min-height : 0px){
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 14vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }

}
@media (min-width: 1439px) and (max-width: 1599px)  and (min-height : 723px)  {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;

  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }

}



/* Standard Desktops - 1600x900 */
@media (min-width: 1599px) and (max-width: 1919px) and (max-height : 723px) and (min-height : 0px)  {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
}
@media (min-width: 1599px) and (max-width: 1919px) and (min-height : 723px)  {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
}



/* Standard Desktops (Full HD) - 1920x1080 */
@media (min-width: 1919px) and (max-width: 2559px) and (max-height : 723px) and (min-height : 0px){
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
}
@media (min-width: 1919px) and (max-width: 2559px)  and (min-height : 723px)  {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
}

/* Large Desktops (2K QHD) - 2560x1440 */
@media (min-width: 2559px) and (max-width: 3439px) and (max-height : 723px) and (min-height : 0px) {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
}
@media (min-width: 2559px) and (max-width: 3439px)  and (min-height : 723px)  {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
}

/* Ultra-Wide Monitors - 3440x1440 */
@media (min-width: 3439px) and (max-width: 3839px)  and (max-height : 723px) and (min-height : 0px)  {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }

}
@media (min-width: 3439px) and (max-width: 3839px)  and (min-height : 723px)  {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }

}

/* Large Desktops (4K UHD) - 3840x2160 */
@media (min-width: 3839px)  and (max-height : 723px) and (min-height : 0px){
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 15vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }

}
@media (min-width: 3839px)  and (min-height : 723px)  {
  .centered-section-main{

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    min-height: 88.31vh;
    font-size: 11.80;
  
  }
  .mainIntro{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 16vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .mainIntroquote{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 5vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }
  .sucessDialogDesc{
    color: #FFF;
  
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  }

}

.sucessDialogDesc{
  color: #FFF;

text-align: center;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
font-weight: lighter;
line-height: normal;
}

.imageLogoLink{
  height: 10vh;
  width: 10vh;
}
.billing-containerDialog {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  width: 80vw;
  background-color:#000;

}


.successDialogDiv{
  position:absolute;
  background-color:#000;
  width:60%;
  margin-left: 20%;
  text-align:center;
  display:flex;
  justify-content:center;
  margin-top:15vh
}














  /* Media query for mobile responsiveness */
  @media screen and (max-width: 768px) {
    .navbar-toggler:focus {
      text-decoration: none;
      outline: 0;
      box-shadow: 0 0 0 ;
  }
.navBar{
    padding: 8px 0;
}
.contactDiv{
  display: contents;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
}

navbar-toggler{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.mainIntro{
  color: #FFF;

text-align: center;
font-family: Roboto;
font-size: 19vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.mainIntroquote{
  color: #FFF;
text-align: center;
font-family: Roboto;
font-size: 8vw;
font-style: normal;
font-weight: 500;
padding: 0 5vw;
line-height: normal;
}
.sucessDialogDesc{
  color: #FFF;

text-align: center;
font-family: Roboto;
font-size: 6vw;
font-style: normal;
font-weight: lighter;
line-height: normal;
}
.sucessDialogDesc{
  color: #FFF;

text-align: center;
font-family: Roboto;
font-size: 6vw;
font-style: normal;
font-weight: lighter;
line-height: normal;
}


.startPadding{
  padding-top: 0;

}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vw;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;

  }

  .contentDesc{
    color: #fff;
  text-align: center;
  padding:10px 9.6vw 0px 9.6vw;
  font-family: Roboto;
  font-size: 4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }


  .buttonStyle{
    border-radius: 30px;
  border: 3px solid #FF9F1A;
  background: #000;
  color: #FFF;
  color: #FFF;
  font-family: Roboto;
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }


  .contentDescList{
    color: #FF9F1A;
  text-align: left;
  font-family: Roboto;
  padding:0 9.6vw 0px 9.6vw;
  
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  
  }

  .centered-section-choose{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    /* min-height: 100vh; */
    padding-top: 10.25vh;
    padding-top: 0;

  }

  .centered-section-contact{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    max-height: 120.25vh;
    padding-top: 0;
    min-width: 90vw;
  }

  .centered-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    min-height: 100vh;
    padding-top: 20.25vh;
  }
  
  .strikeColor{
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 3vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
 

 
  .aboutUsStyle{
    height: '1.25vw';
    width: '1.25vw';
  }
  .footer{
    color: #FFA31A;
  font-family: Roboto;
  font-size: 4vw;
  font-style: normal;
  line-height: normal;
  }
  .desktopBr{
    padding-top: 0px;
  }
  
.desktopBrInside{
  padding-top: 30px;
}
}

  