.mainbody{
    background-color: #000;

}/* Navbar.css */

.imageLogo{
  height: 3vw;
  width: 9vw;
}
.linkFontColor{
   



  align-items: center;
      color: #ffa31a;
      display: flex;
      font-family: Inter;
      font-size: 1.5vw;
      font-style: normal;
      font-weight: 400;
      justify-content: space-around;
      line-height: normal;
      margin-right: 20px;
  }
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  position: sticky;
  top: 0;
  z-index: 100;
  padding:0;
  min-height: 10vh;
}
.navContainer{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100vw;  
    padding: 0;

}


  
  .logo img {
    height: 40px; /* Set the desired height of your logo */
  }
  
  .links {
    display: flex;
  }
  
  .links a {
    color: black;
    text-decoration: none;
    margin-left: 20px;
  }
  
  .links a:hover {
    border-bottom: 2px solid black; /* Add an underline effect on hover */
  }
  .nav-link.active-link {
    color: blue ;
  }
 
  /* Media query for mobile responsiveness */
  @media screen and (max-width: 768px) {
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #333;
      position: sticky;
      top: 0;
      z-index: 100;
      padding:0;
      min-height: 7vh;
    }
     .navbar-toggler-icon {
       background-image: url(../src/images/menu.png);
   }
   .navbar-nav{
     align-items: end;
   }
   .imageLogo{
     height: 8vw;
     width: 22vw;
   }
   .links {
     margin-top: 10px;
     margin-left: 0;
   }
 
   .links a {
     margin: 0;
     margin-right: 1.56vw;;
   }
   .navContainer.container{
     display: flex;
     justify-content: space-between;
     align-items: flex-start;
     max-width: 100%;
     padding: 0px 8px;
   }
  
   .navbar-toggler{
     border: 0;
   }
   
    
     
 .linkFontColor{
   display: flex;
   justify-content: space-around;
 
   align-items: center;
   margin-right: 20px;
 
   color: #ffa31a;
   font-family: Inter;
   font-size: 3vw;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
 }
 button{
   background-color: #FFA31A;
 }
 .buttonStyNav{
   border-radius: 30px;
 border: 1px solid #FF9F1A;
 background: #fff;
 color: #000;
 font-family: Roboto;
 font-size: 2.5vw;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 margin-right: 10px;
 padding: 10px;
 }
   }


  


/* Small Desktops/Laptops - 1024x768 */
@media (min-width: 768px) and (max-width: 1279px) and (max-height : 723px) and (min-height : 0px){
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}

}
@media (min-width: 768px) and (max-width: 1279px) and (min-height : 723px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}

}



/* Small Desktops/Laptops - 1280x800 */
@media (min-width: 1279px) and (max-width: 1365px) and (max-height : 723px) and (min-height : 0px){
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
@media (min-width: 1279px) and (max-width: 1365px) and (min-height : 723px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}




/* Small Desktops/Laptops - 1366x768 */
@media (min-width: 1365px) and (max-width: 1439px)  and (max-height : 723px) and (min-height : 0px) {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
@media (min-width: 1365px) and (max-width: 1439px)  and (min-height : 723px) {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}


/* Medium Desktops - 1440x900 */
@media (min-width: 1439px) and (max-width: 1599px) and (max-height : 723px) and (min-height : 0px){
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
@media (min-width: 1439px) and (max-width: 1599px)  and (min-height : 723px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}



/* Standard Desktops - 1600x900 */
@media (min-width: 1599px) and (max-width: 1919px) and (max-height : 723px) and (min-height : 0px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
@media (min-width: 1599px) and (max-width: 1919px) and (min-height : 723px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}

/* Standard Desktops (Full HD) - 1920x1080 */
@media (min-width: 1919px) and (max-width: 2559px) and (max-height : 723px) and (min-height : 0px){
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
@media (min-width: 1919px) and (max-width: 2559px)  and (min-height : 723px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}

/* Large Desktops (2K QHD) - 2560x1440 */
@media (min-width: 2559px) and (max-width: 3439px) and (max-height : 723px) and (min-height : 0px) {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
@media (min-width: 2559px) and (max-width: 3439px)  and (min-height : 723px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}


/* Ultra-Wide Monitors - 3440x1440 */
@media (min-width: 3439px) and (max-width: 3839px)  and (max-height : 723px) and (min-height : 0px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
@media (min-width: 3439px) and (max-width: 3839px)  and (min-height : 723px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}

/* Large Desktops (4K UHD) - 3840x2160 */
@media (min-width: 3839px)  and (max-height : 723px) and (min-height : 0px){
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
@media (min-width: 3839px)  and (min-height : 723px)  {
  .imageLogo{
    height: 3vw;
    width: 9vw;
  }
  
.linkFontColor{
  align-items: center;
  color: #ffa31a;
  display: flex;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  justify-content: space-around;
  line-height: normal;
  margin-right: 20px;
}
}
