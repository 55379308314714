.BeAwareimageLayout{
    display:flex;
    justify-content: space-between;
    flex-direction:'row';
    text-align: center;
    align-items: center;   
    width: '40vw';
    }

    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-size: 1.5vw;
    margin-left: 2vw;
    line-height: 120%;
    }
    .TwoSecLayout{
    display:flex;
     flex-direction:row;
       align-items:center;
        max-width:40vw;
    } .TwoSecLayoutButton{
        display:flex;
         flex-direction:row;justify-content: left;
           align-items:center;
           min-width:40vw;
  
        

        }
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }

    @media screen and (max-width: 768px) {
        .beAwareOfImage{
            height: 30.42vw;
            width: 30.42vw;
        }
        .TwoSecLayout{
            display:flex;
             flex-direction:row;
               align-items:center;
        max-width:100vw;

            }

            .TwoSecLayoutButton{
                display:flex;
                 flex-direction:row;
                   align-items:center;
            max-width:100vw;    
                justify-content: center;
    
                }
        .tableText{
            color: white;
        font-weight: 500;
        text-align: left;
        font-family: Roboto;
        font-size: 5vw;
        }
    }
     
 /* Small Desktops/Laptops - 1024x768 */
 @media (min-width: 768px) and (max-width: 1279px) and (max-height : 723px) and (min-height : 0px){
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
   
  }
  @media (min-width: 768px) and (max-width: 1279px) and (min-height : 723px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  
  
  
  /* Small Desktops/Laptops - 1280x800 */
  @media (min-width: 1279px) and (max-width: 1365px) and (max-height : 723px) and (min-height : 0px){
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  
  }
  @media (min-width: 1279px) and (max-width: 1365px) and (min-height : 723px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  
  
  
  
  /* Small Desktops/Laptops - 1366x768 */
  @media (min-width: 1365px) and (max-width: 1439px)  and (max-height : 723px) and (min-height : 0px) {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  @media (min-width: 1365px) and (max-width: 1439px)  and (min-height : 723px) {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  
  
  /* Medium Desktops - 1440x900 */
  @media (min-width: 1439px) and (max-width: 1599px) and (max-height : 723px) and (min-height : 0px){
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  @media (min-width: 1439px) and (max-width: 1599px)  and (min-height : 723px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  
  
  
  /* Standard Desktops - 1600x900 */
  @media (min-width: 1599px) and (max-width: 1919px) and (max-height : 723px) and (min-height : 0px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  @media (min-width: 1599px) and (max-width: 1919px) and (min-height : 723px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  
  /* Standard Desktops (Full HD) - 1920x1080 */
  @media (min-width: 1919px) and (max-width: 2559px) and (max-height : 723px) and (min-height : 0px){
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  @media (min-width: 1919px) and (max-width: 2559px)  and (min-height : 723px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  
  /* Large Desktops (2K QHD) - 2560x1440 */
  @media (min-width: 2559px) and (max-width: 3439px) and (max-height : 723px) and (min-height : 0px) {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  @media (min-width: 2559px) and (max-width: 3439px)  and (min-height : 723px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  
  
  /* Ultra-Wide Monitors - 3440x1440 */
  @media (min-width: 3439px) and (max-width: 3839px)  and (max-height : 723px) and (min-height : 0px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  @media (min-width: 3439px) and (max-width: 3839px)  and (min-height : 723px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  
  /* Large Desktops (4K UHD) - 3840x2160 */
  @media (min-width: 3839px)  and (max-height : 723px) and (min-height : 0px){
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  @media (min-width: 3839px)  and (min-height : 723px)  {
    .beAwareOfImage{
        height: 30.42vh;
        width: 30.42vh;
    }
    .tableText{
        color: white;
    font-weight: 500;
    text-align: left;
    font-family: Roboto;
    font-size: 4vh;
    }
  }
  