/* ResponsiveForm.css */

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    background-image: url(../src/formBG.png);
    background-size: inherit;
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    margin: 0;
    font-stretch: condensed;
    min-width: 100vw;
  }
  
  form {
    background-color: white;

    width: 30%;
    padding: 20px;
    margin: 20px 40px 40px 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .fromIntro{
    background-color: #FFA31A;
    color: white;
    width: 30%;
    padding: 20px;
    margin: 40px 40px 0px 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    line-height: normal;
    font-size:2.3vw;


  }
  .fromIntroDesc{
    font-size:1.4vw;
    line-height: normal;

  }
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #FFA31A;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }
  
  button:hover {
  }
  .billing-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 10px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loading-spinner {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  .submitButton{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media screen and (max-width: 768px) {

    .fromIntro{
      background-color: #FFA31A;
      color: white;
      width: 70%;
      padding: 20px;
      margin: 0px 40px 10px 40px;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      line-height: normal;
      font-size:5vw;

    }
    .fromIntroDesc{
      font-size:3vw;
      line-height: normal;

    }
    form {
      background-color: white;
      
      width:70%;
      padding: 2vh;
      margin: 0px;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0);
    }
    input {
      border-radius: 13px;
      width: 100%;
      height: max-content;
      height: min-content;
      margin-bottom: 5%;
      box-sizing: border-box;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-image: url(../src/formBGMobile.png);
      background-size: cover;
      background-position: center;
      background-attachment: scroll;
      background-repeat: no-repeat;
      margin: 0px 0px 0px 0px;
      padding: 20px 0px 0px 0px;
      font-stretch: condensed;
      height: 100vh;
    }
    

  }
  