.GVIcon{
    height: 20vh;
    width: 20vh;
}

.GblobeImage{
    height: 10vh;
    width: 18vh;
}

.tableTextGlobe{
    color: #FFF;
    font-family: Roboto;
    font-size: 3.5vh;
    font-style: normal;
    font-weight: 500;
    margin: 0 2vw 0 2vw;
}

.tableTextGlobeColor{
    color: #FF9F1A;
    font-family: Roboto;
    font-size: 4vh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 4vh;    

}
.sectionTitleGlobe{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }

@media screen and (max-width: 768px) {
    .GblobeImage{
        height: 18vw;
        width: 25vw;
    }
    .sectionTitleGlobe{
        color: #FFF;
        font-family: Roboto;
        font-size:7vw;
        font-style: normal;
        font-weight: bold;
        line-height: normal;  
        text-decoration-line: underline;
      }
      .tableTextGlobe{
        color: #FFF;
        font-family: Roboto;
        font-size: 3.5vw;
        font-style: normal;
        font-weight: 500;
        margin: 0;
    }
    .tableTextGlobeColor{
        color: #FF9F1A;
        font-family: Roboto;
        font-size: 4vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 4vh;    
    
    }
    .GVIcon{
        height: 20vw;
        width: 20vw;
    }
    
}