
.contentDescWarning{
    color: #fff;
  text-align: left;
  font-family: Inter;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
.warningBg{
  height: 45vh;
  width: 80vw;
    margin-left: 5vh;

}
.ImageAlign{
  display:flex;
  justify-content:center;
  flex-direction:row;
  text-align:center;
  align-items:center;
  }
  .ImageCaption{
    font-family: Inter;
      font-size:2.5vw;
      color:#fff;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
  }
  .ImageDesc{
   width:30vw
  }

@media screen and (max-width: 768px) {
  .sectionTitle{
    color: #FFF;
    font-family: Roboto;
    font-size:9vw;
    font-style: normal;
    font-weight: bold;
    line-height: normal;  
    text-decoration-line: underline;
  }
  .ImageDesc{
    width:100%
   }
        .aboutUS-section{

          text-align: left;
          display: flex;
          flex-direction: column;
          align-items: left;
          background-color: black;
          
          padding: 0 5vw 0px 5vw;
      }
        .contentDescWarning{
          color: #fff;
          text-align: left;
          font-family: Roboto;
          font-style: normal;
          line-height: normal;
          font-weight: 500;
          font-size: 5vw;
          }

          .ImageAlign{
            display:block;
            align-items:center;
            width: 100%;
          }
          .ImageCaption{
            font-family: Inter;
            font-size:4vw;
            color:#fff;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align:left;

          }
          .warningBg{
            height: 45vh;
            width: 80vw;
            margin-left:0;
        }
        .ImageCaptionWarning{
          color: #fff;
          text-align: left;
          font-family: Roboto;
          font-style: normal;
          line-height: normal;
          font-weight: 500;
          margin-top: 40px;
          font-size: 5vw; 
        }
}

  /* Small Desktops/Laptops - 1024x768 */
  @media (min-width: 768px) and (max-width: 1279px) and (max-height : 723px) and (min-height : 0px){
    .aboutUS-section{

      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: left;
      background-color: black;
      min-height: 90vh;
      
      padding: 0 5vh 0px 5vh;
  }
  .sectionTitle{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescWarning{
    color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
  }
  .warningBg{
    height: 45vh;
    width: 80vw;
    margin-left: 5vh;

  }
  .ImageCaptionWarning{
    color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
    font-size: 2vw; 
    width: 40vw;
    margin-left: 5vh;
  }
  
  .buttonStyle{
    border-radius: 3vw;
  border: 0.2vw solid #FF9F1A;
  background: #000;
  color: #FFF;
  font-family: Roboto;
  font-size: 3vh;
  font-style: normal;
  line-height: normal;
  padding: 2vh 5vh 2vh 5vh;
  }
  }
  @media (min-width: 768px) and (max-width: 1279px) and (min-height : 723px)  {
    .aboutUS-section{

      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: left;
      background-color: black;
      min-height: 90vh;
      
      padding: 0 5vh 0 5vh;
  }
  .sectionTitle{
    color: #FFF;
  font-family: Roboto;
  font-size: 9vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  }
  .contentDescWarning{
    color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
  }
  .warningBg{
    height: 45vh;
  width: 80vw;
    margin-left: 5vh;
  }
  .ImageCaptionWarning{
    color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
    font-size: 2vw; 
    width: 40vw;
    margin-left: 5vh;
  }
  .buttonStyle{
    border-radius: 3vw;
  border: 0.2vw solid #FF9F1A;
  background: #000;
  color: #FFF;
  font-family: Roboto;
  font-size: 3vh;
  font-style: normal;
  line-height: normal;
  padding: 2vh 5vh 2vh 5vh;
  }
  }


  
/* Small Desktops/Laptops - 1280x800 */
@media (min-width: 1279px) and (max-width: 1365px) and (max-height : 723px) and (min-height : 0px){
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}
.warningBg{
  height: 45vh;
  width: 80vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}

}
@media (min-width: 1279px) and (max-width: 1365px) and (min-height : 723px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
  width: 80vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}

}




/* Small Desktops/Laptops - 1366x768 */
@media (min-width: 1365px) and (max-width: 1439px)  and (max-height : 723px) and (min-height : 0px) {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
  width: 80vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}
@media (min-width: 1365px) and (max-width: 1439px)  and (min-height : 723px) {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
    font-size: 2vw; 
    width: 40vw;
    margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}


/* Medium Desktops - 1440x900 */
@media (min-width: 1439px) and (max-width: 1599px) and (max-height : 723px) and (min-height : 0px){
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}
@media (min-width: 1439px) and (max-width: 1599px)  and (min-height : 723px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}



/* Standard Desktops - 1600x900 */
@media (min-width: 1599px) and (max-width: 1919px) and (max-height : 723px) and (min-height : 0px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}
.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;

}
}
@media (min-width: 1599px) and (max-width: 1919px) and (min-height : 723px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}

/* Standard Desktops (Full HD) - 1920x1080 */
@media (min-width: 1919px) and (max-width: 2559px) and (max-height : 723px) and (min-height : 0px){
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}
@media (min-width: 1919px) and (max-width: 2559px)  and (min-height : 723px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}

/* Large Desktops (2K QHD) - 2560x1440 */
@media (min-width: 2559px) and (max-width: 3439px) and (max-height : 723px) and (min-height : 0px) {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}
@media (min-width: 2559px) and (max-width: 3439px)  and (min-height : 723px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
    font-size: 2vw; 
    width: 40vw;
    margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}


/* Ultra-Wide Monitors - 3440x1440 */
@media (min-width: 3439px) and (max-width: 3839px)  and (max-height : 723px) and (min-height : 0px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}
@media (min-width: 3439px) and (max-width: 3839px)  and (min-height : 723px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}

/* Large Desktops (4K UHD) - 3840x2160 */
@media (min-width: 3839px)  and (max-height : 723px) and (min-height : 0px){
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  font-size: 2vw; 
  width: 40vw;
  margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}
@media (min-width: 3839px)  and (min-height : 723px)  {
  .aboutUS-section{

    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    min-height: 90vh;
    
    padding: 0 5vh 0 5vh;
}
.sectionTitle{
  color: #FFF;
font-family: Roboto;
font-size: 9vh;
font-style: normal;
font-weight: bold;
line-height: normal;
text-decoration-line: underline;
}
.contentDescWarning{
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}.warningBg{
  height: 45vh;
    width: 45vw;
  margin-left: 5vh;
}
.ImageCaptionWarning{
  color: #fff;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
    font-size: 2vw; 
    width: 40vw;
    margin-left: 5vh;
}
.buttonStyle{
  border-radius: 3vw;
border: 0.2vw solid #FF9F1A;
background: #000;
color: #FFF;
font-family: Roboto;
font-size: 3vh;
font-style: normal;
line-height: normal;
padding: 2vh 5vh 2vh 5vh;
}
}