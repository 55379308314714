      .statisticBg{
    height: 30vw;
    width: 50vw;
  }

  .contentDescWarning{
    color: #fff;
  font-family: Inter;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  
  .imageAndDesc{
    display:flex;
     flex-direction:row;
      justify-content:space-around;
      align-items:center;
  }

@media screen and (max-width: 768px) {
  .statisticBg{
    height: 60vw;
    width: 100vw;
    padding: 5vw 5vw;
  }
  .imageAndDesc{
    display:flex;
     flex-direction:column;
      justify-content:center;
      align-items:center;
  }
}
 /* Small Desktops/Laptops - 1024x768 */
 @media (min-width: 768px) and (max-width: 1279px) and (max-height : 723px) and (min-height : 0px){
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 4vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
 .imageLayoutBAO{
  display: flex;
    justify-content: space-between;
    flex-direction: 'row';
    text-align: center;
    align-items: center;
 }
}
@media (min-width: 768px) and (max-width: 1279px) and (min-height : 723px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}



/* Small Desktops/Laptops - 1280x800 */
@media (min-width: 1279px) and (max-width: 1365px) and (max-height : 723px) and (min-height : 0px){
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }

}
@media (min-width: 1279px) and (max-width: 1365px) and (min-height : 723px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}




/* Small Desktops/Laptops - 1366x768 */
@media (min-width: 1365px) and (max-width: 1439px)  and (max-height : 723px) and (min-height : 0px) {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}
@media (min-width: 1365px) and (max-width: 1439px)  and (min-height : 723px) {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}


/* Medium Desktops - 1440x900 */
@media (min-width: 1439px) and (max-width: 1599px) and (max-height : 723px) and (min-height : 0px){
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}
@media (min-width: 1439px) and (max-width: 1599px)  and (min-height : 723px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}



/* Standard Desktops - 1600x900 */
@media (min-width: 1599px) and (max-width: 1919px) and (max-height : 723px) and (min-height : 0px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}
@media (min-width: 1599px) and (max-width: 1919px) and (min-height : 723px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}

/* Standard Desktops (Full HD) - 1920x1080 */
@media (min-width: 1919px) and (max-width: 2559px) and (max-height : 723px) and (min-height : 0px){
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}
@media (min-width: 1919px) and (max-width: 2559px)  and (min-height : 723px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}

/* Large Desktops (2K QHD) - 2560x1440 */
@media (min-width: 2559px) and (max-width: 3439px) and (max-height : 723px) and (min-height : 0px) {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}
@media (min-width: 2559px) and (max-width: 3439px)  and (min-height : 723px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}


/* Ultra-Wide Monitors - 3440x1440 */
@media (min-width: 3439px) and (max-width: 3839px)  and (max-height : 723px) and (min-height : 0px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}
@media (min-width: 3439px) and (max-width: 3839px)  and (min-height : 723px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}

/* Large Desktops (4K UHD) - 3840x2160 */
@media (min-width: 3839px)  and (max-height : 723px) and (min-height : 0px){
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}
@media (min-width: 3839px)  and (min-height : 723px)  {
  .statisticBg{
    height: 70vh;
    width: 50vw;
  }
  .contentDescWarning{
    color: #fff;
    font-family: Roboto;
    font-size: 3vh;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  }
  .imageLayoutBAO{
    display: flex;
      justify-content: space-between;
      flex-direction: 'row';
      text-align: center;
      align-items: center;
   }
}
