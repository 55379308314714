


    .aboutUsStyle1{
        height: 4.25vw;
        width: 4.25vw;
    }

    .imageLayout{
        display:flex;
        justify-content: space-between;
        flex-direction:'row';
        text-align: center;
        align-items: center;    
    }
.tableText{
    color: white;
    font-weight: 500;
    text-align: center;
    font-size:10vw;

}


.tableTextColor{
    color: #FFA31A;
    font-weight: 500;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .buttonStyle{
        border-radius: 30px;
      border: 3px solid #FF9F1A;
      background: #000;
      color: #FFF;
      color: #FFF;
      font-family: Roboto;
      font-size: 3vw;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      }
    .aboutUsStyle1{
        height: 10vw;
        width: 10vw;
    }
    .ToCImage{
        height: 10vw;
        width: 10vw;
        margin-bottom: 1vh;
    }
    .tableText{
        font-family: Inter;
        font-size:10vw;
        color:#fff;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        padding: 0 5vw 0 5vw;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vw;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
}


 /* Small Desktops/Laptops - 1024x768 */
 @media (min-width: 768px) and (max-width: 1279px) and (max-height : 723px) and (min-height : 0px){
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
   .tableTextToC{
            color: white;
            text-align: center;
            font-size:2.5vh;
            font-style: normal;
        font-weight: 500;
        line-height: normal;
            font-family: Roboto;
        
    }
 
    .ToCImage{
        height: 13.21vh;
        width: 13.21vh;
        margin-bottom: 1vh;
    }
  }
  @media (min-width: 768px) and (max-width: 1279px) and (min-height : 723px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
  }


  
/* Small Desktops/Laptops - 1280x800 */
@media (min-width: 1279px) and (max-width: 1365px) and (max-height : 723px) and (min-height : 0px){
 
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    } .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}
@media (min-width: 1279px) and (max-width: 1365px) and (min-height : 723px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}




/* Small Desktops/Laptops - 1366x768 */
@media (min-width: 1365px) and (max-width: 1439px)  and (max-height : 723px) and (min-height : 0px) {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}
@media (min-width: 1365px) and (max-width: 1439px)  and (min-height : 723px) {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}


/* Medium Desktops - 1440x900 */
@media (min-width: 1439px) and (max-width: 1599px) and (max-height : 723px) and (min-height : 0px){
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}
@media (min-width: 1439px) and (max-width: 1599px)  and (min-height : 723px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}



/* Standard Desktops - 1600x900 */
@media (min-width: 1599px) and (max-width: 1919px) and (max-height : 723px) and (min-height : 0px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}
@media (min-width: 1599px) and (max-width: 1919px) and (min-height : 723px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}

/* Standard Desktops (Full HD) - 1920x1080 */
@media (min-width: 1919px) and (max-width: 2559px) and (max-height : 723px) and (min-height : 0px){
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}
@media (min-width: 1919px) and (max-width: 2559px)  and (min-height : 723px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}

/* Large Desktops (2K QHD) - 2560x1440 */
@media (min-width: 2559px) and (max-width: 3439px) and (max-height : 723px) and (min-height : 0px) {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}
@media (min-width: 2559px) and (max-width: 3439px)  and (min-height : 723px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}


/* Ultra-Wide Monitors - 3440x1440 */
@media (min-width: 3439px) and (max-width: 3839px)  and (max-height : 723px) and (min-height : 0px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}
@media (min-width: 3439px) and (max-width: 3839px)  and (min-height : 723px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}

/* Large Desktops (4K UHD) - 3840x2160 */
@media (min-width: 3839px)  and (max-height : 723px) and (min-height : 0px){
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}
@media (min-width: 3839px)  and (min-height : 723px)  {
    .TOfContent-section{

        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: black;
        min-height: 90vh;
        padding: 0 5vh 0 5vh;
    }
    .sectionTitle {
        color: #FFF;
        font-family: Roboto;
        font-size: 6vh;
        font-style: normal;
        font-weight: lighter;
        line-height: normal;
        text-decoration-line: underline;
    }
    .tableTextToC{
        color: white;
        text-align: center;
        font-size:2.5vh;
        font-style: normal;
    font-weight: 500;
    line-height: normal;
        font-family: Roboto;
    
}
.ToCImage{
    height: 13.21vh;
    width: 13.21vh;
    margin-bottom: 1vh;
}
}