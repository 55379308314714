.contactDiv{
    display: contents;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
  line-height: 100%;

  }
  .contactSec{
    color: #FFF;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }

  .contactSecLink{
    color: #FFF;
  font-family: Roboto;
  font-size: 3.5vh;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
  align-self: center;
  }

  .address{
    color: #FFF;
    font-family: Roboto;
    font-size:2.5vh;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-decoration: none;
  }

  .contactImage{

      width:8vh;
      height:6vh;
      margin-right:2vh
  }
  .contactImageLinkedIn{
    width:6vh;
    height:5vh;
    margin-right:2vh
  }

@media screen and (max-width: 768px) {
  .contactSec{
    color: #FFF;
  font-family: Roboto;
  font-size: 6vw;
  font-style: normal;
  font-weight: 500;
  }
  .contactSecLink{
    color: #FFF;
  font-family: Roboto;
  font-size: 5vw;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
  align-self: center;
  }
  .contactDiv{
    display: contents;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
  line-height:250%;

  }
  .contactImage{

    width:5vh;
    height:4vh;
    margin-right:2vh
}

.contactImageLinkedIn{
  width:4vh;
  height:3.5vh;
  margin-right:2vh
}
.address{
  color: #FFF;
  font-family: Roboto;
  font-size:3vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
}
}